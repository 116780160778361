export const ROUTES = {
  home: '/',
  login: '/login',
  dashboard: '/dashboard',

  users: '/users',
  userDetail: '/users/:id',

  maps: '/maps',
  mapDetail: '/maps/:id',

  documents: '/documents',
  documentDetail: '/documents/:id',

  inventoryItems: '/inventoryItems',
  inventoryItemDetail: '/inventoryItems/:id',

  categories: '/categories',
  categoryDetail: '/categories/:id',

  warehouses: '/warehouses',
  warehouseDetail: '/warehouses/:id',
  stock: '/stock',

  shipments: '/shipments',
  shipmentDetail: '/shipments/:id',

  accounts: '/accounts',
  accountDetail: '/accounts/:id',

  institutions: '/institutions',
  institutionDetail: '/institutions/:id',

  transactions: '/transactions',
  transactionDetail: '/transactions/:id',

  vehicles: '/vehicles',
  vehicleDetail: '/vehicles/:id',
}
